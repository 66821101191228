import React from 'react';

import '../styles/ContactUs.scss';

const ContactUs = (props) => {
    return (
        <section id="contactus" className="space-before">
            <div className="container">
                <h1 className="title" style={{ color: "grey" }}>
                    {props.data.contactUs.title}
                </h1>
                <h2 className="subtitle">
                    {props.data.contactUs.subtitle}
                </h2>
                <h3 className="info">
                    <span className="email">{props.data.contactUs.email.text}</span>{props.data.contactUs.email.domain}
                </h3>
                <h2>{props.data.contactUs.or}</h2>
                <button onClick={() => window.open('https://calendly.com/raulaxioma/30min', '_blank')}>
                    <span className={'icon-calendar'}></span> {props.data.contactUs.calendly}
                </button>
            </div>
        </section>
    );
}

export default React.memo(ContactUs);
