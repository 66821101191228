import React, { Component } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// Components
import Header from './core/components/Header';
import Cover from './core/components/Cover';
import Services from './core/components/Services';
import Projects from './core/components/Projects';
import AboutUs from './core/components/AboutUs';
import StrategicAlliance from './core/components/StrategicAlliance';
import Technologies from './core/components/Technologies';
import ContactUs from './core/components/ContactUs';
import Testimonials from './core/components/Testimonials';
import PreFooter from './core/components/PreFooter';
import Footer from './core/components/Footer';

// Utilities
import './App.scss';
import dataes from './data.es';
import dataen from './data.en';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transparent: true,
      data: dataen,
      language: 'en',
    }

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  _handleChangeLanguage = (event) => {
    if (event.target.checked) {
      this.setState({ language: 'es', data: dataes });
    } else {
      this.setState({ language: 'en', data: dataen });
    }
  }

  handleScroll(event) {
    if (window.scrollY > 50) {
      if (this.state.transparent !== false) {
        this.setState({
          transparent: false
        });
      }
    } else {
      if (this.state.transparent !== true) {
        this.setState({
          transparent: true
        });
      }
    }
  }

  render() {
    return (
      <div className="App" id="App">
        <div className="language">
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.language === 'es'}
                  onClick={this._handleChangeLanguage}
                  value="language"
                  color="primary"
                />
              }
              label={<span className="languageText">EN/ES</span>}
            />
          </FormGroup>
        </div>

        <Header transparent={this.state.transparent} data={this.state.data} />

        <div>
          <Cover data={this.state.data} />
          <Services data={this.state.data} />
          <div style={{ width: "100%", backgroundColor: 'black' }}>
            <iframe
              style={{ width: "50vw", height: "28.125vw", border: "none", padding: 0, margin: 0 }}
              src="https://www.youtube.com/embed/UBlqHdv8g4E?si=pH4x-PjBbDI-1tlh&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;loop=1&amp;playlist=UBlqHdv8g4E"
              title="ApertureData Testimonial"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            >
            </iframe>
          </div>
          <Projects data={this.state.data} />
          <AboutUs data={this.state.data} />
          <StrategicAlliance data={this.state.data} />
          <Technologies data={this.state.data} />
          <Testimonials data={this.state.data} />
          <ContactUs data={this.state.data} />
          <PreFooter data={this.state.data} />
        </div>

        <Footer data={this.state.data} />
      </div>
    );
  }
}

export default App;
