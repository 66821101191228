import React from 'react';

import '../styles/Services.scss';

const Services = (props) => {
    return (
        <section id="services" className="space-before">
            {showServices(props.data.service.list)}
        </section>
    );
};

const showServices = (services) => {
    return services.map((service, i) => {
        return (
            <div className="service-card" key={i}>
                <div className="upper-section">
                    <div className={service.className}></div>
                    <h1 className="title">
                        {service.title}
                    </h1>
                </div>
                <h2 className="description">
                    {service.description}
                </h2>
                <button onClick={() => window.open('https://calendly.com/raulaxioma/30min', '_blank')}>
                    <span className={'icon-calendar'}></span> {service.contact}
                </button>
            </div>
        );
    });
};

export default React.memo(Services);
