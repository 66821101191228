import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import '../styles/PreFooter.scss';

const PreFooter = (props) => {
    return (
        <section id="prefooter">
            <div id="logo">
                <img src={props.data.prefooter.companyImage} className="footer-company-logo" alt={props.data.prefooter.companyImageAlt} />
            </div>
            <div id="social-networks">
                {showSocialNetworks(props.data.prefooter.socialNetworksList)}
            </div>
        </section>
    );
};

const showSocialNetworks = (socialNetworks) => {
    return socialNetworks.map((socialNetwork, i) => (
        <div className={'logo ' + socialNetwork.specificClassForBackgrounColor} key={i}>
            <Tooltip title={socialNetwork.tooltipTitle}>
                <a href={socialNetwork.url} className="url" target={"_blank"} rel="noopener noreferrer">
                    <span className={socialNetwork.icon}></span>
                </a>
            </Tooltip>
        </div>
    ));
};

export default React.memo(PreFooter);
