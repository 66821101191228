import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import '../styles/Header.scss';
import '../../assets/icomoon/style.css';

const Header = (props) => {
	return (
		<header className={props.transparent ? null : "nav-transition"}>
			<div className={props.transparent ? "logo" : "logo nav-transition-logo"}>
				<a href="./#home">
					<img src={props.transparent ? props.data.header.logo.companyLogoImageStatic : props.data.header.logo.companyLogoImageFloating} className="company-logo" alt={props.data.header.logo.companyLogoAlt} />
				</a>
			</div>
			<nav role="navigation" id="nav" className={props.transparent ? null : "nav-transition-nav"}>
				<input className="trigger" type="checkbox" id="mainNavButton" />
				<label htmlFor="mainNavButton" className={props.transparent ? null : "trigger nav-transition-label"}></label>
				<ul>
					<li key={'home'}>
						<Tooltip title={props.data.header.menu.home.tooltipTitle}>
							<a href={props.data.header.menu.home.url} target={null} rel="noopener noreferrer" className={props.transparent ? null : "nav-transition-a"}>
								<span className="squareBracket">[</span><span className={props.data.header.menu.home.icon}></span><span className="squareBracket">]</span>
							</a>
						</Tooltip>
					</li>
					{ showSections(props.data.header.menu.sections, props.transparent) }
				</ul> 
			</nav>
		</header>
	);
};

const showSections = (sections, transparent) => {
	return sections.map((section, i) => {
		return (
			<li key={i}>
				<a href={section.url} className={transparent ? null : "nav-transition-a"}>
					{section.title === "Contacto" || section.title === "Contact" ? <b>{section.title}</b> : section.title}
				</a>
			</li>
		);
	});
};

export default React.memo(Header);
