export default {
    header: {
        logo: {
            companyLogoAlt: 'Axioma Studios',
            companyLogoImageStatic: require('./assets/imgs/header/bigLogo.png'),
            companyLogoImageFloating: require('./assets/imgs/header/bigLogo2.png'),
        },
        menu: {
            home: {
                tooltipTitle: 'Home',
                url: './#home',
                icon: 'icon-home',
            },
            sections: [
                {
                    title: 'Services',
                    url: './#services',
                },
                {
                    title: 'Projects',
                    url: './#projects',
                },
                {
                    title: 'Team',
                    url: './#aboutus',
                },
                {
                    title: 'Testimonials',
                    url: './#testimonials',
                },
                {
                    title: 'Contact',
                    url: './#contactus',
                },
            ],
        },
    },
    cover: {
        slogan1: 'Software',
        slogan2: 'solutions',
        phrase: 'CREATING VALUE',
        invitationToScrollImage: require('./assets/imgs/cover/arrowDown.png'),
    },
    aboutUs: {
        title: 'We can',
        subtitle: 'Help you to design, start and accelerate your software projects, adding talented people, teams or customized software solutions to your business. Our team is qualified to develop efficiently robust and scalable software products aligned with your exact needs. Our professional services remove the overhead associated with recruiting and let our clients gain quick access to top talent in the software industry.',
    },
    service: {
        title: '',
        list: [
            {
                title: 'Staff Augmentation',
                description: 'We provide you with the talent needed to create, scale, or boost your software development team, allowing you to focus on your core business. From single developers to team solutions and tech support.',
                className: 'image-service1',
                contact: 'Book a meeting now',
            },
            {
                title: 'Custom Software Development',
                description: 'We help startups and business design, build and launch custom software solutions. We can help you reimagine your processes and convert into a successful software product tailored to your needs.',
                className: 'image-service2',
                contact: 'Book a meeting now',
            },
            {
                title: 'Consultancy',
                description: 'We can accelerate your next steps to scale your software products, and help you decide architecture, infrastructure, software optimizations, and refactoring aligned to your unique requirements.',
                className: 'image-service3',
                contact: 'Book a meeting now',
            },
        ],
    },
    projects: {
        title: 'Projects',
        subtitle: 'Here you will be able to explore some of the projects we have worked on. Follow us on social media to find out the latest news about Axioma Studios.',
        url: 'Check website...',
        list: [
            {
                image: require('./assets/imgs/projects/coverProject1.gif'),
                coverImage: require('./assets/imgs/projects/coverProject1.gif'),
                title: 'Laser Attack',
                type: 'complex systems',
                year: '2017',
                text: 'Laser Attack is a laser tag system developed entirely by Axioma Studios. The project includes hardware (suits, weapons, etc) and software. The last one consists of several modules, each one in charge of well separated tasks such as communication with the suits, game controller, user interface, web server, etc. To make it possible, many technologies were integrated to tackle the complexity of each module and the system as a whole: Miwi, Embedded C, C++11, C#, gRPC, Angular 2+, SQL. Aside from the regular laser tag characteristics, Laser Attack features different game modes, variable damage depending on the weapon type, virtual money to spend on suit improvements, light granades, and several other attributes.',
                tags: ['miwi', 'c', 'c#', 'c++', 'grpc', 'angularjs', 'sql'],
            },
            {
                image: require('./assets/imgs/projects/project3.png'),
                coverImage: require('./assets/imgs/projects/coverProject3.png'),
                title: 'Primer Alerta',
                type: 'mobile app',
                year: '2019',
                text: 'Primer Alerta is a mobile app that allows to connect all Firefighters active member for a given Firehouse. You can notify about fires, accidents, rescues, help or any other emergency in real-time and privately. The app was developed using React Native for the app, and VueJS for the administration panel. Thanks Webmedia Digital for trusting us on support for developing the main features for Primer Alerta.',
                tags: ['reactnative', 'nodejs', 'express', 'vuejs', 'mongodb'],
            },
            {
                image: require('./assets/imgs/projects/project6.png'),
                coverImage: require('./assets/imgs/projects/coverProject6.png'),
                title: 'Alunort',
                type: 'e-commerce',
                year: '2020',
                text: 'Alunort is a company from Tucumán-Argentina leader on the distribution of aluminum profiles and construction accessories. At the web site you will be able to find all available products and prepare your order, that will be delivered to your address. We developed the website fully, including the integration with the payment gateway. We used React and Angular in the frontend, and NodeJS in the backend.',
                tags: ['nodejs', 'express', 'grpc', 'angularjs', 'mysql'],
            },
            {
                image: require('./assets/imgs/projects/project7.png'),
                coverImage: require('./assets/imgs/projects/coverProject7.png'),
                title: 'ApertureData',
                type: 'web site',
                year: 'SINCE 2020',
                text: 'ApertureData is a company that created the most suitable images and videos database engine, powered with a strong and easy-to-use query system for format, size and data manipulation, and Machine Learning ready functionalities. We worked together to develop several products and internal features, handling the public Website, the Database Admin Panel and some backend initializers, using React, NodeJS and C++ tehcnologies, through different hiring processes, such as projects and staffing.',
                tags: ['nodejs', 'express', 'gcp', 'react'],
            },
            {
                image: require('./assets/imgs/projects/project8.png'),
                coverImage: require('./assets/imgs/projects/coverProject8.png'),
                title: 'AgroManager',
                type: 'mobile app',
                year: 'SINCE 2020',
                text: 'AgroManager is an app that helps to have a daily status of your crops. The objective is to digitalize what\'s known as the Crops Notebook. FOr this project we worked with React Native for the app development, and NodeJS in the backend.',
                tags: ['nodejs', 'express', 'gcp', 'react-native', 'react'],
            },
            {
                image: require('./assets/imgs/projects/coverProject9.gif'),
                coverImage: require('./assets/imgs/projects/coverProject9.gif'),
                title: 'Connected Cabin',
                type: 'web site',
                year: 'SINCE 2021',
                text: 'Connected Cabin is a marketing project developed for Collins Aerospace (Ratheon Technologies) which aims to present how the interior of private jet cabins looks like and how devices connect. The project has been developed using Angular and ThreeJS.',
                tags: ['angular', 'threejs'],
            },
            {
                image: require('./assets/imgs/projects/coverProject10.gif'),
                coverImage: require('./assets/imgs/projects/coverProject10.gif'),
                title: 'Rails',
                type: 'web site',
                year: 'SINCE 2022',
                text: 'Rails is a marketing project developed for Collins Aerospace (Ratheon Technologies), later adquired by Wabtec Corporation, which aims different devices are used through a train passenger journey. The project has been developed using Angular and ThreeJS.',
                tags: ['angular', 'threejs'],
            },
        ]
    },
    technologies: {
        tech: {
            title: 'Technologies',
            list: ['devicon-angularjs-plain', 'devicon-c-plain', 'devicon-cplusplus-plain', 'devicon-csharp-plain-wordmark', 'devicon-css3-plain-wordmark', 'devicon-dot-net-plain-wordmark', 'devicon-docker-plain-wordmark', 'devicon-bitbucket-plain-wordmark', 'devicon-babel-plain', 'devicon-git-plain-wordmark', 'devicon-html5-plain-wordmark', 'devicon-javascript-plain', 'devicon-mocha-plain', 'devicon-mongodb-plain-wordmark', 'devicon-mysql-plain-wordmark', 'devicon-nginx-original', 'devicon-nodejs-plain-wordmark', 'devicon-postgresql-plain-wordmark', 'devicon-react-original-wordmark', 'devicon-sass-original', 'devicon-sequelize-plain-wordmark', 'devicon-typescript-plain'],
        },
        proc: {
            title: 'Processes',
            list: ['Domain Driven Development', 'Test Driven Development', 'Agile Scrum Methodology', 'Continuos Delivery', 'Continuos Integration'],
        },
        devops: {
            title: 'Devops',
            list: ['AWS - GCP - Digital Ocean', 'No-SQL Database', 'SQL Database', 'Queue Management', 'Automated Deployments', 'Load Balancing'],
        },
    },
    testimonials: {
        testimonialsTitle: 'Testimonials from our clients',
        testimonialsText: '"Collaborating with Axioma Studios, we fostered an exceptional synergy, achieving significant progress and consistently delivering key strategic features. They are an integral part of our team."',
        testimonialsAuthor: 'Client - Luis Remis, CTO',
        testimonialsAuthorCompany: 'ApertureData',
        testimonialsText2: '"I\'m really glad to partner with Axioma for our projects. They are committed and genuinely care for the work we do, they are experts in their domain and, above all, they are wonderful people to work with. I wholeheartedly recommend them."',
        testimonialsAuthor2: 'Partner - Juan Pablo Manson, Founder',
        testimonialsAuthorCompany2: 'The UX Department',
        testimonialsText3: '"Our 10+ year relationship with Axioma has been nothing short of transformative. They have delivered cutting-edge technology and unparalleled performance on a variety of real-time 3D applications. The team is knowledgeable, professional, and always willing to go the extra mile to ensure our projects run smoothly. From concept to execution, they have provided invaluable support and expertise."',
        testimonialsAuthor3: 'Client - John O\'Keefe, Founder',
        testimonialsAuthorCompany3: 'John O\'Keefe Design',
        testimonialsText4: '"We started working with Axioma on the development of our Mobile App, which is related to the agricultural sector. They participated on the process from scratch. Working with them was like having a custom suit made, they understood our needs very quickly. They are also very reliable on meeting our deadlines. I would totally recommend working with Axioma."',
        testimonialsAuthor4: 'Client - Jorge Brandan, Founder',
        testimonialsAuthorCompany4: 'Agromanager',
    },
    contactUs: {
        title: 'Should we start?',
        subtitle: 'We\'re eager to learn about your project and confident in our ability to assist you in developing an effective solution. Please don\'t hesitate to get in touch with us.',
        email: {
            text: 'info',
            domain: '@axiomastudios.com'
        },
        or: 'or',
        calendly: 'Book a meeting now',
    },
    alliances: {
        title: 'Partnership',
        subtitle1: 'Since August 2020 we decided to merge with the company leader on technology at the North of Argentina with more than 40 years of experience.',
        subtitle2: 'We are involved with other digital agencies so we can deliver a professional support approaching every project area.',
        merged: {
            tooltipTitle: 'sistelco',
            url: 'http://www.sistelco.com/',
            image: require('./assets/imgs/strategicAlliance/logoSistelco.png'),
        },
        list: [
            {
                tooltipTitle: 'the ux department',
                url: 'https://theuxdepartment.com/',
                image: require('./assets/imgs/strategicAlliance/theuxdepartment.png'),
            },
        ],
    },
    prefooter: {
        companyImage: require('./assets/imgs/prefooter/footerLogo.png'),
        companyImageAlt: 'Axioma Studios',
        socialNetworksList: [
            {
                specificClassForBackgrounColor: 'calendly',
                tooltipTitle: 'Book a meeting',
                url: 'https://calendly.com/raulaxioma/30min',
                icon: 'icon-calendar',
            },
            {
                specificClassForBackgrounColor: 'facebook',
                tooltipTitle: 'facebook',
                url: 'https://www.facebook.com/axiomastudios/',
                icon: 'icon-facebook2',
            },
            {
                specificClassForBackgrounColor: 'instagram',
                tooltipTitle: 'instagram',
                url: 'https://www.instagram.com/axioma.studios/',
                icon: 'icon-instagram',
            },
            {
                specificClassForBackgrounColor: 'linkedin',
                tooltipTitle: 'linkedin',
                url: 'https://www.linkedin.com/company/axiomastudios/',
                icon: 'icon-linkedin',
            },
            {
                specificClassForBackgrounColor: 'twitter',
                tooltipTitle: 'twitter',
                url: 'https://twitter.com/AxiomaStudios/',
                icon: 'icon-twitter',
            },
            {
                specificClassForBackgrounColor: 'whatsapp',
                tooltipTitle: 'whatsapp',
                url: 'https://web.whatsapp.com/send?phone=+5493815037586&text=Hola,%20quiero%20hacer%20una%20consulta!',
                icon: 'icon-whatsapp',
            },
        ],
    },
    footer: {
        url: 'http://www.axiomastudios.com',
        urlText: 'Axioma Studios LLC',
        text: 'All rights reserved',
        address: '2330 Ponce de Leon Blvd, Coral Gables, FL 33134',
    },
}
