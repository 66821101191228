import React from 'react';

import '../styles/AboutUs.scss';

const AboutUs = (props) => {
    return (
        <section id="aboutus" className="space-before">
            <div className="container">
                <h1>
                    {props.data.aboutUs.title}
                </h1>
                <h2>
                    {props.data.aboutUs.subtitle}
                </h2>
            </div>
        </section>
    );
};

export default React.memo(AboutUs);
