import React from 'react';

import '../styles/Footer.scss';

const Footer = (props) => {
    return (
        <footer>
            <small>
                <a href={props.data.footer.url} target="_blank" rel="noopener noreferrer">
                    {props.data.footer.urlText}
                </a>
                {` ${(new Date()).getFullYear()} - ${props.data.footer.text}`}
            </small>
            <small>
                {props.data.footer.address}
            </small>
        </footer>
    );
};

export default React.memo(Footer);
