import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';

import '../styles/Projects.scss';

const Projects = (props) => {
    const [project, setProject] = useState({});

    return (
        <section id="projects" className="space-before">
            <h1 className="with-line">{props.data.projects.title}</h1>
            <h2>{props.data.projects.subtitle}</h2>
            <div className="carousel">
                {showProjects(props.data.projects.list, setProject)}
            </div>
            <div className="background-line"></div>
            <input type="checkbox" name="modal-state" id="modal-state" className="modal-state"></input>
            <div className="modal-overlay">
                <label htmlFor="modal-state" className="modal-overlay-close"></label>
                <div className="modal">
                    <label className="button button-close modal_close" htmlFor="modal-state"><span>x</span></label>
                    <div className="project-info">
                        <div className="project-info-header">
                            <div className="project-info-image-container">
                                {project.coverImage && <img className="project-info-image" src={project.coverImage} alt="" />}
                            </div>
                            <div className="project-info-basic">
                                <div className="project-info-title-description">
                                    <h1 className="project-info-title">{project.title}</h1>
                                    <h2 className="project-info-description">
                                        <span className="project-info-type">{project.type}</span>
                                        <span className="project-info-year">{' - ' + project.year}</span>
                                    </h2>
                                </div>
                                <h3 className="project-info-tags">
                                    {project.tags && showTags(project.tags)}
                                </h3>
                            </div>
                        </div>
                        <div className="project-info-text">
                            {project.text}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const showProjects = (projects, setProject) => {
    const handleOnDragStart = e => e.preventDefault();
    const responsive = {
        0: {
            items: 1
        },
        768: {
            items: 2
        },
        1024: {
            items: 3
        }
    };
    const stagePadding = {
        paddingLeft: 0,
        paddingRight: 0
    }

    return (
        <AliceCarousel
            mouseDragEnabled={true}
            dotsDisabled={false}
            buttonsDisabled={true}
            responsive={responsive}
            stagePadding={stagePadding}
            duration={500}
            autoPlay={true}
            autoPlayInterval={2500}
        >
            {projects.reverse().map((project, i) => {
                return (
                    <label className="button" htmlFor="modal-state" onClick={() => setProject(project)} key={i} >
                        <div className="project-card">
                            <div className="card-image">
                                <img src={project.image} onDragStart={handleOnDragStart} className="yours-custom-class" alt="" />
                            </div>
                            <div className="card-text">
                                <h1>{project.title}</h1>
                                <h2>
                                    <span className="first">{project.type}</span>
                                    <span className="second"> - {project.year}</span>
                                </h2>
                            </div>
                        </div>
                    </label>
                );}
            )}
        </AliceCarousel>
    );
};

const showTags = (tags) => {
    return tags.map((tag, i) => (
        <span className="tag" key={i}>
            <span className="square-brackets">[</span>
            <span className="tag-text">{tag}</span>
            <span className="square-brackets">]</span>
        </span>
    ));
};

export default React.memo(Projects);
