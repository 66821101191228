import React from 'react';

import '../styles/Cover.scss';

const Cover = (props) => {
    return (
        <section className="cover" id="home">
            <div className="container">
                <h1>
                    { props.data.cover.slogan1 }
                </h1>
                <h2>
                    { props.data.cover.slogan2 }
                </h2>
                <h3>
                    { props.data.cover.phrase }
                </h3>
                <a href="./#services">
                    <img src={props.data.cover.invitationToScrollImage} className='arrow' alt='arrow down' />
                </a>
            </div>
        </section>
    );
};

export default React.memo(Cover);
