import React from 'react';

import '../styles/Technologies.scss';

const Technologies = (props) => {
    return (
        <section id="technologies">
            <div className="tech">
                <h1>{props.data.technologies.tech.title}</h1>
                <span>
                    {showTechnologies(props.data.technologies.tech.list)}
                </span>
            </div>
            <div className="proc">
                <h1>{props.data.technologies.proc.title}</h1>
                <ul>
                    {showProcesses(props.data.technologies.proc.list)}
                </ul>
            </div>
            <div className="devops">
                <h1>{props.data.technologies.devops.title}</h1>
                <ul>
                    {showDevops(props.data.technologies.devops.list)}
                </ul>
            </div>
        </section>
    );
}

const showTechnologies = (techs) => {
    return techs.map((tech, i) => (
        <i className={tech} key={i}></i>
    ));
};

const showProcesses = (procs) => {
    return procs.map((proc, i) => (
        <li key={i}>{proc}</li>
    ));
};

const showDevops = (devops) => {
    return devops.map((devop, i) => (
        <li key={i}>{devop}</li>
    ));
};

export default React.memo(Technologies);
