export default {
    header: {
        logo: {
            companyLogoAlt: 'Axioma Studios',
            companyLogoImageStatic: require('./assets/imgs/header/bigLogo.png'),
            companyLogoImageFloating: require('./assets/imgs/header/bigLogo2.png'),
        },
        menu: {
            home: {
                tooltipTitle: 'Inicio',
                url: './#home',
                icon: 'icon-home',
            },
            sections: [
                {
                    title: 'Servicios',
                    url: './#services',
                },
                {
                    title: 'Proyectos',
                    url: './#projects',
                },
                {
                    title: 'Nosotros',
                    url: './#aboutus',
                },
                {
                    title: 'Testimonios',
                    url: './#testimonials',
                },
                {
                    title: 'Contacto',
                    url: './#contactus',
                },
            ],
        },
    },
    cover: {
        slogan1: 'Soluciones',
        slogan2: 'de Software',
        phrase: 'CREANDO VALOR',
        invitationToScrollImage: require('./assets/imgs/cover/arrowDown.png'),
    },
    aboutUs: {
        title: 'Podemos',
        subtitle: 'Ayudarte a diseñar, iniciar y acelerar tus proyectos de software, añadiendo personas talentosas, equipos o soluciones de software personalizadas a tu negocio. Nuestro equipo está calificado para desarrollar eficientemente productos de software robustos y escalables alineados con tus necesidades exactas. Nuestros servicios profesionales eliminan la carga asociada con la contratación y permiten a nuestros clientes acceder rápidamente al mejor talento de la industria del software.',
    },
    service: {
        title: '',
        list: [
            {
                title: 'Staff Augmentation',
                description: 'e proporcionamos el talento necesario para crear, escalar o potenciar tu equipo de desarrollo de software, permitiéndote concentrarte en tu negocio principal. Desde desarrolladores individuales hasta soluciones de equipo y soporte técnico.',
                className: 'image-service1',
                contact: 'Agenda una reunión ahora',
            },
            {
                title: 'Desarrollo de Software a Medida',
                description: 'Ayudamos a startups y negocios a diseñar, construir y lanzar soluciones de software personalizadas. Podemos ayudarte a reimaginar tus procesos y convertirlos en un producto de software exitoso adaptado a tus necesidades.',
                className: 'image-service2',
                contact: 'Agenda una reunión ahora',
            },
            {
                title: 'Consultoría',
                description: 'Podemos acelerar tus próximos pasos para escalar tus productos de software y ayudarte a decidir la arquitectura, infraestructura, optimizaciones de software y refactorización alineadas con tus requisitos únicos.',
                className: 'image-service3',
                contact: 'Agenda una reunión ahora',
            },
        ],
    },
    projects: {
        title: 'Proyectos',
        subtitle: 'Te mostramos algunos de los proyectos en los que hemos trabajado. Seguinos en nuestras redes sociales para descubrir las últimas novedades de Axioma Studios.',
        url: 'Visitar sitio...',
        list: [
            {
                image: require('./assets/imgs/projects/coverProject1.gif'),
                coverImage: require('./assets/imgs/projects/coverProject1.gif'),
                title: 'Laser Attack',
                type: 'sistemas complejos',
                year: '2017',
                text: 'Laser Attack es un sistema de Laser Tag (juego deportivo que simula un combate) desarrollado enteramente por Axioma Studios. El proyecto incluye hardware (trajes, armas, etc) y software. El último consiste en diversos módulos, cada uno a cargo de tareas específicas como ser comunicación con los trajes, controladores del juego, interfaz de usuario, servidor web, etc. Para hacerlo posible, muchas tecnologías fueron integradas para atacar la complejidad de cada módulo y el sistema como un todo: Miwi, C Embebido, C++11, C#, gRPC, Angular 2+, SQL. Más allá de las características básicas de cualquier laser tag, Laser Attack permite diferentes modos de juego, daño variable dependiendo del tipo de arma, dinero virtual para utilizar en mejoras del traje, granadas de luz y muchos otros atributos.',
                tags: ['miwi', 'c', 'c#', 'c++', 'grpc', 'angularjs', 'sql'],
            },
            {
                image: require('./assets/imgs/projects/project3.png'),
                coverImage: require('./assets/imgs/projects/coverProject3.png'),
                title: 'Primer Alerta',
                type: 'aplicación móvil',
                year: '2019',
                text: 'Primer Alerta es una aplicación para celulares que permite tener conectados a todos los miembros activos del Cuartel de Bomberos. La App permite notificar sobre un incendio, accidente, rescate, auxilio u otro tipo de emergencia en tiempo real, de forma inmediata y privada. La aplicación móvil fue desarrollada utilizando React Native, mientras que para el panel de administración se usó VueJS. Gracias Webmedia Digital por confiar en nosotros para el soporte del desarrollo de algunas de las principales funciones de Primer Alerta.',
                tags: ['reactnative', 'nodejs', 'express', 'vuejs', 'mongodb'],
            },
            {
                image: require('./assets/imgs/projects/project6.png'),
                coverImage: require('./assets/imgs/projects/coverProject6.png'),
                title: 'Alunort',
                type: 'e-commerce',
                year: '2020',
                text: 'Alunort es una empresa tucumana líder en la distribución de perfiles de aluminio y accesorios para la construcción. En su sitio web podrás conocer todos los productos que ofrecen y realizar tu compra online, que será enviada a tu domicilio. Desarrollamos el sitio web en su totalidad, incluída la integración con el proveedor de pagos. Utilizamos React y Angular para el frontend, y NodeJS para el backend.',
                tags: ['nodejs', 'express', 'grpc', 'angularjs', 'mysql'],
            },
            {
                image: require('./assets/imgs/projects/project7.png'),
                coverImage: require('./assets/imgs/projects/coverProject7.png'),
                title: 'ApertureData',
                type: 'sitio web',
                year: 'DESDE 2020',
                text: 'ApertureData es una compañía con el motor de bases de datos mejor preparado para imágenes y videos, brindando un sólido y fácil sistema de lenguaje para cambio de formato, tamaño y manipulación de datos, con funcionalidades listas para Machine Learning. Trabajamos juntos para desarrollar distintos productos y features internos, manejando el sitio web público, el panel de administración de la base de datos, y algunos inicializadores del backend, utilizando tecnologías de React, NodeJS y C++, a través de diferentes modalidades de contratación como ser proyectos y staffing.',
                tags: ['nodejs', 'express', 'gcp', 'react', 'c++', 'mysql'],
            },
            {
                image: require('./assets/imgs/projects/project8.png'),
                coverImage: require('./assets/imgs/projects/coverProject8.png'),
                title: 'AgroManager',
                type: 'aplicación móvil',
                year: 'DESDE 2020',
                text: 'AgroManager es una app utilizada para llevar un control diario de las tareas realizadas en el campo. Su objetivo es el de digitalizar la reconocida agenda de campo. Para este proyecto trabajamos con React Native en el desarrollo de la app, y NodeJS en el backend.',
                tags: ['nodejs', 'express', 'gcp', 'react-native', 'react'],
            },
            {
                image: require('./assets/imgs/projects/coverProject9.gif'),
                coverImage: require('./assets/imgs/projects/coverProject9.gif'),
                title: 'Connected Cabin',
                type: 'sitio web',
                year: 'DESDE 2021',
                text: 'Connected Cabin es un proyecto de marketing desarrollado para Collins Aerospace (Ratheon Technologies) con el cual se busca presentar cómo se vería el interior de la cabina de un jet privado y la conexión entre distintos dispositivos. El proyecto se desarrolló utilizando Angular y ThreeJS.',
                tags: ['angular', 'threejs'],
            },
            {
                image: require('./assets/imgs/projects/coverProject10.gif'),
                coverImage: require('./assets/imgs/projects/coverProject10.gif'),
                title: 'Rails',
                type: 'sitio web',
                year: 'DESDE 2022',
                text: 'Rails es un proyecto de marketing desarrollado para Collins Aerospace (Ratheon Technologies), luego adquirido por Wabtec Corporation, con el cual se busca presentar distintos dispositivos que se utilizan durante el viaje de un pasajero de tren. El proyecto se desarrolló utilizando Angular y ThreeJS.',
                tags: ['angular', 'threejs'],
            },
        ]
    },
    technologies: {
        tech: {
            title: 'Tecnologías',
            list: ['devicon-angularjs-plain', 'devicon-c-plain', 'devicon-cplusplus-plain', 'devicon-csharp-plain-wordmark', 'devicon-css3-plain-wordmark', 'devicon-dot-net-plain-wordmark', 'devicon-docker-plain-wordmark', 'devicon-babel-plain', 'devicon-git-plain-wordmark', 'devicon-html5-plain-wordmark', 'devicon-javascript-plain', 'devicon-mocha-plain', 'devicon-mongodb-plain-wordmark', 'devicon-mysql-plain-wordmark', 'devicon-nginx-original', 'devicon-nodejs-plain-wordmark', 'devicon-postgresql-plain-wordmark', 'devicon-react-original-wordmark', 'devicon-sass-original', 'devicon-sequelize-plain-wordmark', 'devicon-typescript-plain'],
        },
        proc: {
            title: 'Procesos',
            list: ['Domain Driven Development', 'Test Driven Development', 'Agile Scrum Methodology', 'Continuos Delivery', 'Continuos Integration'],
        },
        devops: {
            title: 'Devops',
            list: ['AWS - GCP - Digital Ocean', 'No-SQL Database', 'SQL Database', 'Queue Management', 'Automated Deployments', 'Load Balancing'],
        },
    },
    testimonials: {
        testimonialsTitle: 'Testimonios de nuestros clientes',
        testimonialsText: '"Colaborando con Axioma Studios, fomentamos una sinergia excepcional, logrando un progreso significativo y entregando de manera constante funcionalidades estratégicas clave. Son una parte integral de nuestro equipo."',
        testimonialsAuthor: 'Cliente - Luis Remis, CTO',
        testimonialsAuthorCompany: 'ApertureData',
        testimonialsText2: '"Estoy realmente encantado de asociarme con Axioma para nuestros proyectos. Están comprometidos y realmente se preocupan por el trabajo que hacemos, son expertos en su dominio y, sobre todo, son personas maravillosas con las que trabajar. Los recomiendo de todo corazón."',
        testimonialsAuthor2: 'Aliado - Juan Pablo Manson, Fundador',
        testimonialsAuthorCompany2: 'The UX Department',
        testimonialsText3: '"Nuestra relación de más de 10 años con Axioma ha sido nada menos que transformadora. Han entregado tecnología de vanguardia y un rendimiento incomparable en una variedad de aplicaciones 3D en tiempo real. El equipo es conocedor, profesional y siempre está dispuesto a hacer un esfuerzo adicional para asegurar que nuestros proyectos se desarrollen sin problemas. Desde el concepto hasta la ejecución, han brindado un apoyo y una experiencia invaluables."',
        testimonialsAuthor3: 'Cliente - John O\'Keefe, Fundador',
        testimonialsAuthorCompany3: 'John O\'Keefe Design',
        testimonialsText4: '"Comenzamos a trabajar con Axioma en el desarrollo de nuestra aplicación móvil, que está relacionada con el sector agrícola. Participaron en el proceso desde cero. Trabajar con ellos fue como tener un traje hecho a medida, entendieron nuestras necesidades muy rápidamente. También son muy confiables para cumplir con nuestros plazos. Definitivamente recomendaría trabajar con Axioma."',
        testimonialsAuthor4: 'Cliente - Jorge Brandan, Fundador',
        testimonialsAuthorCompany4: 'Agromanager',
    },
    contactUs: {
        title: '¿Comenzamos?',
        subtitle: 'Nos encantaría conocer acerca de tu proyecto. Estamos seguros de que podemos brindarte una solución efectiva. Por favor, contactanos.',
        email: {
            text: 'info',
            domain: '@axiomastudios.com'
        },
        or: 'o',
        calendly: 'Agenda una reunión ahora',
    },
    alliances: {
        title: 'Alianzas estratégicas',
        subtitle1: 'A partir de Agosto de 2020 nos fusionamos con la empresa líder en tecnología del Norte Argentino con más de 40 años de experiencia.',
        subtitle2: 'Trabajamos activamente junto a otras agencias digitales para brindar un soporte profesional e integral abordando todas las áreas del proyecto.',
        merged: {
            tooltipTitle: 'sistelco',
            url: 'http://www.sistelco.com/',
            image: require('./assets/imgs/strategicAlliance/logoSistelco.png'),
        },
        list: [
            {
                tooltipTitle: 'the ux department',
                url: 'https://theuxdepartment.com/',
                image: require('./assets/imgs/strategicAlliance/theuxdepartment.png'),
            },
        ],
    },
    prefooter: {
        companyImage: require('./assets/imgs/prefooter/footerLogo.png'),
        companyImageAlt: 'Axioma Studios',
        socialNetworksList: [
            {
                specificClassForBackgrounColor: 'calendly',
                tooltipTitle: 'Agenda una reunión',
                url: 'https://calendly.com/raulaxioma/30min',
                icon: 'icon-calendar',
            },
            {
                specificClassForBackgrounColor: 'facebook',
                tooltipTitle: 'facebook',
                url: 'https://www.facebook.com/axiomastudios/',
                icon: 'icon-facebook2',
            },
            {
                specificClassForBackgrounColor: 'instagram',
                tooltipTitle: 'instagram',
                url: 'https://www.instagram.com/axioma.studios/',
                icon: 'icon-instagram',
            },
            {
                specificClassForBackgrounColor: 'linkedin',
                tooltipTitle: 'linkedin',
                url: 'https://www.linkedin.com/company/axiomastudios/',
                icon: 'icon-linkedin',
            },
            {
                specificClassForBackgrounColor: 'twitter',
                tooltipTitle: 'twitter',
                url: 'https://twitter.com/AxiomaStudios/',
                icon: 'icon-twitter',
            },
            {
                specificClassForBackgrounColor: 'whatsapp',
                tooltipTitle: 'whatsapp',
                url: 'https://web.whatsapp.com/send?phone=+5493815037586&text=Hola,%20quiero%20hacer%20una%20consulta!',
                icon: 'icon-whatsapp',
            },
        ],
    },
    footer: {
        url: 'http://www.axiomastudios.com',
        urlText: 'Axioma Studios LLC',
        text: 'Todos los derechos reservados',
        address: '2330 Ponce de Leon Blvd, Coral Gables, FL 33134',
    },
}
