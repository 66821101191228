import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import '../styles/StrategicAlliance.scss';

const StrategicAlliance = (props) => {
    return (
        <section id="alliance">
            <div className="container">
                <h1>
                    {props.data.alliances.title}
                </h1>
                <h2>
                    {props.data.alliances.subtitle1}
                </h2>
                <Tooltip title={props.data.alliances.merged.tooltipTitle}>
                    <a href={props.data.alliances.merged.url} target={"_blank"} rel="noopener noreferrer">
                        <img src={props.data.alliances.merged.image} className="company-logo" alt={"merged company logo"} />
                    </a>
                </Tooltip>
                <h2>
                    {props.data.alliances.subtitle2}
                </h2>
                <div className="logos">
                    {showAlliances(props.data.alliances.list)}
                </div>
            </div>
        </section>
    );
};

const showAlliances = (alliances) => {
    return alliances.map((alliance, i) => (
        <Tooltip title={alliance.tooltipTitle} key={i}>
            <a href={alliance.url} target={"_blank"} rel="noopener noreferrer">
                <img src={alliance.image} className="company-logo" alt={"allied company logo"} />
            </a>
        </Tooltip>
    ));
};

export default React.memo(StrategicAlliance);
